import { named, withDependencies } from '@wix/thunderbolt-ioc'
import { BIReporter, BISymbol, CommonConfig, SiteFeatureConfigSymbol } from '@wix/thunderbolt-symbols'
import { ICommonConfig, ICommonConfigState } from './types'
import { name } from './symbols'

const commonConfigFactory = (initialCommonConfig: CommonConfig, biReporter: BIReporter): ICommonConfig => {
	const state: ICommonConfigState = {
		commonConfig: initialCommonConfig,
		subscribers: [],
	}

	return {
		getCommonConfig: () => state.commonConfig,
		updateCommonConfig: (property, value) => {
			if (property === 'bsi') {
				biReporter.setDynamicSessionData({ bsi: value })
			}
			state.commonConfig = { ...state.commonConfig, [property]: value }
			state.subscribers.forEach((subscriber) => subscriber(state.commonConfig))
		},
		registerToCommonConfigChange: (subscriber) => state.subscribers.push(subscriber),
	}
}

export const CommonConfigImpl = withDependencies([named(SiteFeatureConfigSymbol, name), BISymbol], commonConfigFactory)
